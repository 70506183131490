<template>
  <v-dialog
      :value="showDialog"
      :persistent="false"
      width="1500"
      @input="closeDialog"
  >
    <v-card>
      <v-card-title class="headline">
        <span><i class="fa fa-user"></i>Редактирование баннера</span>
      </v-card-title>

      <v-card-text class="d-flex justify-center">
        <form class="banner-form-wrapper">
          <v-layout column>
            <form>
              <div class="d-flex flex-row align-center justify-center mt-5">
                <v-img
                    max-height="80%"
                    contain
                    v-if="!useFallbackImage || imgSrc"
                    @click="openImage"
                    @error="imageError"
                    class="banner-img"
                    :src="src"
                />
                <img
                    v-else
                    width="424"
                    height="238.5"
                    src="@/assets/images/default-thumb.png"
                />
              </div>
              <div class="d-flex flex-row align-center">
                <v-text-field
                    class="mt-0 pt-0 link"
                    v-model="link"
                    full-width="true"
                    label="Ссылка на подробную информацию"
                    hide-details="auto"
                    required
                ></v-text-field>
              </div>
              <div
                  :class="{'flex-column': $vuetify.breakpoint.width < 660, 'align-center': $vuetify.breakpoint.width >= 660}"
                  class="d-flex flex-row">
                <div :class="{'mt-5': $vuetify.breakpoint.width < 660}" class="d-flex justify-start">
                  <v-btn
                      :disabled="!image && useFallbackImage"
                      @click="updateBanner"
                      color="success"
                      class="mr-3"
                      elevation="2"
                  >Сохранить
                  </v-btn>
                  <v-btn
                      :disabled="!bannerExists"
                      @click="deleteBanner"
                      color="error"
                      class="mr-3"
                      elevation="2"
                  >Удалить
                  </v-btn>
                </div>
                <div :class="{'order-first': $vuetify.breakpoint.width < 660}" class="d-flex flex-grow-1">
                  <v-file-input
                      :prepend-icon="$vuetify.breakpoint.width < 660 ? '' : undefined"
                      v-model="filename"
                      @change="chooseImage"
                      @click:clear="clearImage"
                      class="mt-0 pt-0 image-upload"
                      full-width
                      accept="image/*"
                      label="Добавить файл"
                      persistent-hint
                      hint="Рекомендуемый размер для баннера 1110 x 240 px"
                  ></v-file-input>
                </div>
              </div>
            </form>
          </v-layout>
        </form>

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton class="mr-3" color="secondary" @click="$emit('close')">Закрыть</CButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EntityModalMixin from '@/views/grid_elements/mixins/EntityModalMixin';
import {serialize} from 'object-to-formdata';
import {showMessage} from "@/lib/toasted";
import {showConfirm} from '@/lib/swal2';
import api from "@/api";
import {base_url} from "@/lib/axios";
import {MAIN_PAGE_BANNER} from "@/config/common_image_codes.js"


export default {
  name: "BannerModal",
  mixins: [EntityModalMixin],
  async mounted() {
    const {data: {status, data}} = await api.banner.getBanner(MAIN_PAGE_BANNER);

    if (status === 'ok') {
      this.bannerExists = true;
      this.link = data.link
    }
  },
  data: () => ({
    base_url,
    cacheKey: +new Date(),
    bannerExists: false,
    link: '',
    filename: null,
    image: null,
    imgSrc: null,
    useFallbackImage: false,
    showModal: false
  }),
  methods: {
    chooseImage(file) {
      if (file) {
        this.useFallbackImage = false;
        this.image = file;
        this.imgSrc = URL.createObjectURL(this.image);
      }
    },
    async updateBanner() {
      let resp;

      const {isConfirmed} = await showConfirm('Вы уверены что хотите сохранить баннер ?');

      if (!isConfirmed) {
        return;
      }

      try {
        resp = await api.banner.updateBanner(MAIN_PAGE_BANNER, serialize({
          link: this.link,
          image: this.image
        }))

        if (resp.data.status === 'ok') {
          this.generateCacheKey();
          this.bannerExists = true;
          this.clearImage();
        }
      } catch (e) {
        resp = e.response;
      }

      const {data: {status, message}} = resp;
      showMessage(status, message);
    },
    async deleteBanner() {
      const {isConfirmed} = await showConfirm('Вы уверены что хотите удалить баннер ?');

      if (!isConfirmed) {
        return;
      }

      let resp;

      try {
        resp = await api.banner.deleteBanner(MAIN_PAGE_BANNER)

        if (resp.data.status === 'ok') {
          this.bannerExists = false;
          this.useFallbackImage = true;
          this.link = null;
        }
      } catch (e) {
        resp = e.response;
      }

      const {data: {status, message}} = resp;
      showMessage(status, message);
    },
    openImage() {
      window.open(this.src, '_blank');
    },
    clearImage() {
      this.filename = null;
      this.image = null;
      this.imgSrc = null;
    },
    imageError() {
      this.useFallbackImage = true;
    },
    generateCacheKey() {
      this.cacheKey = +new Date();
    }
  },
  computed: {
    src() {
      return (this.imgSrc ? this.imgSrc : `${base_url}/api/common-images/${MAIN_PAGE_BANNER}?rnd=` + this.cacheKey);
    }
  }
}
</script>

<style lang="sass">
.banner-form-wrapper
  align-self: center
  max-width: 80%

.page-container
  height: 100%

.link
  width: 100%

.image-upload
  width: 300px

.banner-img
  cursor: pointer

//.v-responsive__content
//  max-width: 80% !important
//
//  @media (max-width: 804px)
//    max-width: 70% !important

</style>
