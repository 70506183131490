<template>
  <div class="page-container">
    <CRow class="table-actions-row">
      <v-col sm="4" cols="12" class="pl-0">
        <breadcrumbs
            :items="crumbs"
        />
      </v-col>
      <v-col sm="8" class="d-flex justify-end align-center">
        <CButton @click="showModal = true" class="add-product-modal" color="info"><i
            class="la la-plus"></i> Редактировать баннер
        </CButton>
      </v-col>
    </CRow>
    <v-card class="card d-flex" min-height="712.5px">
    </v-card>
    <banner-modal @close="showModal = false" v-if="showModal" :show-dialog="showModal"></banner-modal>
  </div>
</template>

<script>
import PageMixin from "@/views/mixins/PageMixin";
import Breadcrumbs from "@/views/components/breadcrumbs";
import BannerModal from "./modals/BannerModal";

export default {
  name: "MainPageBanner",
  mixins: [PageMixin],
  components: {
    Breadcrumbs,
    BannerModal
  },
  data: () => ({
    showModal: false
  })
}
</script>

<style scoped>
.page-container {
  height: 100%
}
</style>