export default {
    computed: {
        crumbs() {
            let routes = this.$route.matched;
            let lastRoute = (routes.slice(-1))[0];

            return [
                {
                    disabled: true,
                    text: 'Главная',
                    iconClasses: 'la la-home',
                    href: ''
                },
                {
                    link: true,
                    text: lastRoute.name,
                    // href: lastRoute.path
                    to: lastRoute.path

                }
            ]
        }
    }
}