import {showMessage} from "@/lib/toasted";

export default {
    methods: {
        closeDialog() {
            this.$emit('close');
        },
        disableSave(status) {
            if(this.disabledBtn) {
                this.disabledBtn(status);
            }
        },
        prepareCreateEntityParams() {
            return [this.data];
        },
        prepareUpdateEntityParams() {
            return [this.currentItem.id, this.data];
        },
        async submit() {
            let resp;

            try {
                this.disableSave(true);
                if (this.currentItem) {
                    resp = await this.updateEntity(...this.prepareUpdateEntityParams());
                } else {
                    resp = await this.createEntity(...this.prepareCreateEntityParams());
                }
            } catch (e) {
                resp = e.response;
            }

            const {data: {status = null, message = ''}} = resp;
            showMessage(status, message);
            this.disableSave(false);
            if (status === 'ok') {
                this.$emit('stored');
            }
        }
    },
    props: {
        showDialog: {
            type: Boolean,
            required: true
        },
        currentItem: {
            type: Object
        }
    },
};
